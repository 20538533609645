.prescriber-new-modal--city-state-zip {
  .prescriber-new-modal--city-state-zip--wrapper {
    width: 100%;
  }
  .city {
    width: 36%;
  }
  .zip {
    width: 90px;
  }
}