.header_primary {
  background-color: #FFF;
  padding-top: 5px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 100;

  .eordering & {
    border-top: none;
  }
}

.header_primary-utilities {
  padding-top: 15px;

  .create-new-case {
    margin-right: $padding-small;
  }
}

.has-nav-primary {
  background-color: #FFF;
  border-bottom: 1px solid $gray-lighter;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, .1);
}

.product-logo {
  margin-bottom: $padding-medium;
  margin-right: $padding-xlarge;
  width: 220px;
  max-width: 100%;

  + .nav-primary {
    padding-left: 285px;
  }
}

.search-wrapper {
  display: inline-block;
  max-width: 250px;
  margin-right: 10px;
  position: relative;
  top: 2px;
  vertical-align: middle;

  .input-group .form-control {
    width: 250px;
  }

  .has-nav-user & {

    // Make it inline with the nav-user elements
    + ul {
      display: inline-block;
    }

    input {
      border-radius: $border-radius-base;
      height: 20px;
      position: relative;
      top: -3px;
      font-size: .8em;
      line-height: 1.5;
      width: 200px;
    }
  }

  .header_primary-utilities & {
    .input-group input.form-control {
      border-radius: $border-radius-base;
    }
  }
}