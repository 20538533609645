.patient-quick-add--wrapper {
  #days_chosen {
    width: 58px !important;
  }
  #months_chosen {
    width: 104px !important;
  }
  #year {
    width: 80px !important;
  }
}