.patient-new-modal--city-state-zip {
  .city {
    width: 32%;
  }
  .zip {
    width: 90px;
  }
//added to fix validation on carv-180
  [class*=' col-'],
  .col-xs-4{

    input, .chosen-container-single, .form-control{
      width: 100% !important;
    }
  }
}
