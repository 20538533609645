.table-recent-orders {
  font-size: .9em;

  .popover-content {
    font-size: .9em;

    table {
      td:first-child {
        width: 90px;
      }
    }
  }

  td.po {
    word-break: break-all;
  }
}
