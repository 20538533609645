.nav-primary {
  > ul ul {
    display: none;
  }

  .nav-dropdown-active {
    position: absolute;
    top: 24px;
    padding-left:0;
    padding-right: 0;
    min-width: 170px;

    > a {
      background: $gray-lighter;
      border: 1px solid darken($gray-lighter,15%);
      border-bottom: none;
      border-radius: $border-radius-base $border-radius-base 0 0;
      box-shadow: 3px 3px 4px rgba(0,0,0,.2);
      padding-top: $padding-small;
      white-space: nowrap;
    }

    &.active > a {
      border-bottom: none;
    }

    ul {
      @include list-unstyled;
      display: block;
      background: #FFF;
      border: 1px solid darken($gray-lighter,15%);
      border-radius: 0 0 $border-radius-base $border-radius-base;
      position: absolute;
      top: 100%;
      left: 0px;
      right: 0px;
      box-shadow: 3px 3px 4px rgba(0,0,0,.2);

      li {
        width: 100%;

        &:last-child {
          border-radius: 0 0 $border-radius-base $border-radius-base;
        }
        &:not(:last-child) {
          a {
            border-bottom: 1px solid darken($gray-lighter,15%);
          }
        }
        a {
          display: block;
          border: none;
          color: $color-primary;
          line-height: 1.1;
          padding: ($padding-small*1.25) $padding-small $padding-small;
          text-transform: none;
        }
      }
    }
  }
}

.nav-dropdown-active ul li {
  width: 100%;
}
