.utility-header {
  background-color: #fff;
  border-bottom: 2px solid $gray-lighter;
  margin: 0 -15px $padding-medium;
  padding-top: $padding-medium;
  padding-bottom: $padding-small;

  h1 {
    margin-top: $padding-small + 3px;
    margin-bottom: $padding-small - 3px;
  }

  .my-cases-my-prescribers {
    .form-inline .form-group {
      width: 48% !important;

      .chosen-container {
        width: 100% !important;
      }

    }
  }
  
  &.utility-header__ordering-only-image {
    background: url('@@imgCSS/order-header.jpg') no-repeat top left;
    background-size: cover;
    height: 211px;
    padding: 0;
    
  }
}
.utility-header--no-border {
  border-bottom: none;
}